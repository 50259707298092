const NewRelicIcon = () => {
	return (
		<svg
			className=''
			viewBox='-2 0 31 31'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M22.3821 11.0773V20.9227L13.8572 25.8464V32L27.7144 24.0005V7.9995L22.3821 11.0773Z'
				fill='#00AC69'
			></path>
			<path
				d='M13.8572 6.15563L22.3821 11.0773L27.7144 7.9995L13.8572 0L0 7.9995L5.33031 11.0773L13.8572 6.15563Z'
				fill='#1CE783'
			></path>
			<path
				d='M8.52689 19.0788V28.9242L13.8572 32V16.001L0 7.9995V14.1551L8.52689 19.0788Z'
				fill='white'
			></path>
		</svg>
	);
};

export default NewRelicIcon;
