const PhpStormIcon = () => {
	return (
		<svg viewBox='0 0 128 128'>
			<defs>
				<linearGradient
					id='storm-a'
					gradientUnits='userSpaceOnUse'
					x1='25.8'
					y1='33.6'
					x2='33.95'
					y2='17.31'
					gradientTransform='rotate(.104) scale(1.21905)'
				>
					<stop offset='0' stopColor='#af1df5'></stop>
					<stop offset='.21' stopColor='#bc20e4'></stop>
					<stop offset='.63' stopColor='#dd29b8'></stop>
					<stop offset='1' stopColor='#ff318c'></stop>
				</linearGradient>
				<linearGradient
					id='storm-b'
					gradientUnits='userSpaceOnUse'
					x1='1.56'
					y1='67.23'
					x2='35.95'
					y2='21.8'
					gradientTransform='rotate(.104) scale(1.21905)'
				>
					<stop offset='.02' stopColor='#6b57ff'></stop>
					<stop offset='.42' stopColor='#b74af7'></stop>
					<stop offset='.75' stopColor='#ff318c'></stop>
				</linearGradient>
				<linearGradient
					id='storm-c'
					gradientUnits='userSpaceOnUse'
					x1='89.3'
					y1='65.79'
					x2='65.46'
					y2='9.23'
					gradientTransform='rotate(.104) scale(1.21905)'
				>
					<stop offset='0' stopColor='#293896'></stop>
					<stop offset='.08' stopColor='#3b3aa2'></stop>
					<stop offset='.29' stopColor='#6740c0'></stop>
					<stop offset='.49' stopColor='#8a44d8'></stop>
					<stop offset='.68' stopColor='#a347e9'></stop>
					<stop offset='.86' stopColor='#b249f3'></stop>
					<stop offset='1' stopColor='#b74af7'></stop>
				</linearGradient>
				<linearGradient
					id='storm-d'
					gradientUnits='userSpaceOnUse'
					x1='74.68'
					y1='93.59'
					x2='58.6'
					y2='70.03'
					gradientTransform='rotate(.104) scale(1.21905)'
				>
					<stop offset='.02' stopColor='#6b57ff'></stop>
					<stop offset='.78' stopColor='#b74af7'></stop>
				</linearGradient>
			</defs>
			<path
				fill='url(#storm-a)'
				d='m70.082 27.281-3.898-11.8-40.73-8.778-19.97 22.59L27.43 40.52V27.28Zm0 0'
			></path>
			<path
				fill='url(#storm-b)'
				d='M27.43 34.96 5.484 29.294l11.157 67 10.75-.086Zm0 0'
			></path>
			<path
				fill='url(#storm-c)'
				d='M100.57 27.281H62.402l15.336-13.629 29.551 5.489 15.227 37.836-21.934 21.785Zm0 0'
			></path>
			<path
				fill='url(#storm-d)'
				d='m100.598 56.477-.028 43.949H37.742l1.621 9.558 39.45 13.75 43.703-26.16Zm0 0'
			></path>
			<path fill='#000' d='M27.43 27.43h73.14v73.14H27.43Zm0 0'></path>
			<path
				fill='#fff'
				d='M36.547 86.746h27.43v4.574h-27.43Zm23.918-26.683 3.57-4.278a12.625 12.625 0 0 0 8.219 3.34c2.437 0 3.973-.988 3.973-2.598v-.074c0-1.535-.95-2.328-5.547-3.496-5.547-1.414-9.118-2.953-9.118-8.414v-.086c0-4.984 4.012-8.289 9.63-8.289a15.85 15.85 0 0 1 10.214 3.5l-3.144 4.559a12.877 12.877 0 0 0-7.157-2.707c-2.316 0-3.535 1.062-3.535 2.437v.086c0 1.805 1.22 2.437 5.938 3.66 5.586 1.461 8.73 3.461 8.73 8.25v.086c0 5.461-4.172 8.535-10.105 8.535a17.479 17.479 0 0 1-11.668-4.402M36.57 36.57h11.215c6.535 0 10.496 3.88 10.496 9.473v.074c0 6.34-4.937 9.63-11.078 9.63h-4.535v8.218H36.57Zm10.801 13.813c3.012 0 4.781-1.793 4.781-4.145v-.074c0-2.703-1.879-4.156-4.879-4.156h-4.605v8.375Zm0 0'
			></path>
		</svg>
	);
};

export default PhpStormIcon;
