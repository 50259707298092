const BambooIcon = () => {
	return (
		<svg viewBox='0 0 128 128'>
			<defs>
				<linearGradient
					id='bamboo-original-a'
					gradientUnits='userSpaceOnUse'
					x1='11.217'
					y1='7.486'
					x2='11.217'
					y2='21.607'
					gradientTransform='scale(4)'
				>
					<stop offset='0' stopColor='#0052cc'></stop>
					<stop offset='1' stopColor='#2684ff'></stop>
				</linearGradient>
				<linearGradient
					id='bamboo-original-b'
					gradientUnits='userSpaceOnUse'
					x1='11.168'
					y1='7.486'
					x2='11.168'
					y2='21.607'
					gradientTransform='scale(4)'
				>
					<stop offset='0' stopColor='#0052cc'></stop>
					<stop offset='1' stopColor='#2684ff'></stop>
				</linearGradient>
			</defs>
			<path
				d='M104.133 70.633h-20c-1.235 0-2.465.96-2.602 2.191-1.097 7.946-7.945 14.11-16.164 14.11l5.344 24.656c19.45-2.602 34.652-18.492 36.023-38.219.137-1.504-1.097-2.738-2.601-2.738zm0 0M69.746 69.95l28.766-24.657c1.234-1.098 1.234-2.879 0-3.973L69.746 16.664c-1.644-1.508-4.246-.273-4.246 1.918v49.313c-.137 2.328 2.465 3.562 4.246 2.054zm0 0'
				fill='#2684ff'
			></path>
			<path
				d='M24.273 75.977C26.88 96.247 44.273 112 65.367 112V86.934c-9.039 0-16.437-7.262-16.437-16.301zm0 0'
				fill='url(#bamboo-original-a)'
			></path>
			<path
				d='M24 70.223v.41h25.066c0-9.04 7.399-16.301 16.438-16.301L60.16 29.676C39.89 32.28 24.137 49.402 24 70.223zm0 0'
				fill='url(#bamboo-original-b)'
			></path>
		</svg>
	);
};

export default BambooIcon;
